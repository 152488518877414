import styled from "styled-components";

const Container = styled.section `
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: white;
`;

export default Container;