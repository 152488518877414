import styled from "styled-components";

const Button = styled.button `
    cursor: pointer;
    background-color: #8DC73F;
    border: 2px solid #8DC73F;
    color: white;
    font-size: 1.2em;
    padding: .9em 1.3em;
    text-wrap: nowrap;
    font-weight: 700;
    transition: all .3s ease-in-out;

    &:active, &:focus, &:hover {
        background-color: white;
        color: #8DC73F;
    }
`
export default Button;