import styled from "styled-components";
import Section from "../components/Section";
import SendMessage from "../utils/SendMessage";

import { ChatAiWidget } from "@sendbird/chat-ai-widget";
// import { App as SendbirdApp } from "@sendbird/uikit-react";
import "@sendbird/chat-ai-widget/dist/style.css";
// import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
// import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";

import { SENDBIRD_INFO } from "../utils/Constants";

var id = SENDBIRD_INFO.appId;
var bot = SENDBIRD_INFO.botId;

const ChatWrapper = styled.div `
  #aichatbot-widget-window {
    display: flex !important;
    position: relative;
    top: -3em;
    width: 100%;
    height: auto;
    max-height: 75vh;

    @media (min-width: 600px) {
      top: 0px;
      max-width: 830px;
      width: 95vw;
      height: 75vh;
    }
  }

  #aichatbot-widget-window > div:first-child {
    width: 100vw;
    height: auto;
    min-height: 75vh;
  }

  #aichatbot-widget-button {
    display: none;
  }

  #aichatbot-widget-close-icon {
    display: none;
  }
`;

function ChatBot() {
  return (
    <Section>
      <ChatWrapper>
        <ChatAiWidget
          applicationId = {id}
          botId = {bot}
          deviceType = "mobile"
          enableResetHistoryOnConnect = "true"
          enableEmojiFeedback = "false"
        />
        <SendMessage />
      </ChatWrapper>
    </Section>
  );
};

export default ChatBot;