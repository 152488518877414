import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import Container from '../components/Container';
import Button from '../components/Button';
import Section from '../components/Section';

import ChatIcon from '../img/icons/chaticon.svg';

const EntryCard = styled(Container) `
    max-width: 680px;
    padding: 6em 3em;
    justify-content: flex-start;
    position: relative;
    height: auto;

    @media (max-width: 860px ) {
        overflow: hidden;
        margin: 1em;
        padding: 6em 1.2em;
    }
`;

const IconContainer = styled.div `
    background-color: #B14FC5;
    border: none;
    padding: 1em .7em;
    width: 100%;
    max-width: 155px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -40px;
    top: 55px;

    img {
        width: 100%;
        max-width: 80px;
        height: auto:
    }

    @media (max-width: 860px) {
        max-width: 65px;
        padding: 1em 1.5em;
        right: 0px;
        top: 0px;
    }
`;

const EntryCardInner = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 2em;
    align-items: flex-start;
    width: 100%;

    @media (min-width: 768px) {
        width: 80%;
    }
`;

function Front() {
    const navigate = useNavigate();

    const buttonClick = () => {
        Cookies.set('getInner', 'true', { expires: 1 });
        navigate('/chat');
    };

    return (
        <Section>
            <EntryCard>
                <IconContainer>
                    <img src={ChatIcon} alt="Chat Icon" />
                </IconContainer>
                <EntryCardInner>
                    <h1>Welcome to Maternal & Family Health Services Chat!</h1>
                    <p>Our chatbot can help with personal questions but will never need your personal information. </p>
                    <p>By clicking the below button and proceeding, I agree not to enter any personal identifying information, such as name, phone number, fax number, email address, credit card number, social security number, medical record number, health plan number, account number, certificate or license numbers or mailing address.</p>
                    <Button onClick={buttonClick}>I Agree</Button>
                </EntryCardInner>  
            </EntryCard>
        </Section>
    );
};
export default Front;