import styled from "styled-components";

const Section = styled.section `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    // background: center / cover no-repeat white;
    margin-top: 1em;

    @media (min-width: 600px) {
        height: auto;
        min-height: 90vh;
        margin-top: 0;
    }
`;

export default Section;