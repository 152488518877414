import styled from "styled-components";
import Container from "./Container";
import MFHSLogo from "../img/logos/mfhs-logo.svg";

const HeaderContainer = styled(Container) `
  display: flex;
  height: 99px;
  border-bottom: 2px solid #EBEBEB;
`;

const InnerContainer = styled.div `
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  max-width: 1600px;
  margin: .5em auto;
`;

const Logo = styled.div `
  img {
    width: 100%;
    height: auto;
    max-width: 200px;
  }
`;

function Header() {
  return (
      <HeaderContainer>
        <InnerContainer> 
          <Logo>
            <a href="/"><img src={MFHSLogo} alt="Maternal & Family Health Services Logo" /></a>
          </Logo>
        </InnerContainer>
      </HeaderContainer>
  )
};  
export default Header;