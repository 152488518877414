import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';

// containers
import Front from './Front';
import ChatBot from './ChatBot';

// components
import Header from '../components/Header';
import GlobalStyle from '../utils/GlobalStyles';

const PrivateRoute = ({ children }) => {
  const getInner = Cookies.get('getInner');
  return getInner ? children : <Navigate to="/" />;
};

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={ <Front /> } />
          <Route 
            path="/chat" 
            element={
              <PrivateRoute>
                  <ChatBot />
              </PrivateRoute>
            } 
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;